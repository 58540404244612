import communicationServices from '../../../../services/supplier/supplier-recall'
import { COMMUNICATION_TYPE } from '../../../../misc/commons'
import { Supplier_Disruptions_AgGrid, AG_GridCustomizedTableHeaders_Key } from '../../../../misc/tableHeaders'

export default {
    data() {
        return {
            organization_id: null,
            rowModelType: 'serverSide',
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            total: 0,
            items: [],
            item: {},
            status: '',
            gridApi: null,
            columnApi: null,
            search: {
                status: null,
                supplier: null,
                recallName: null
            },
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    beforeMount() {
        this.columnDefs = Supplier_Disruptions_AgGrid
    },
    created() {
        this.rowModelType = 'serverSide'
        if (this.$store.state.lastedRouteNotAuth.fullPath != undefined && this._checkPermissions(['Supplier_ManageRecalls'])) {
            return this.$router.push({ path: this.$store.state.lastedRouteNotAuth.fullPath })
        }
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Supplier_Login.Disruption_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Supplier_Login?.Disruption_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onCellClicked(params) {
            if (params.colDef.field == 'status') {
                this.addMoreRespClick(params.data)
                return
            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'view') {
                    this.viewRecall(params.data)
                }
                if (params.event.target.dataset.action === 'edit') {
                    this.editRecall(params.data)
                }
                if (params.event.target.dataset.action === 'delete') {
                    this.deleteCommunication(params.data)
                }
                return
            }else{
                this.viewRecall(params.data)
            }
        },
        onFilterChanged(event) {
            if(this.table_Connection_Data != undefined)
            {
                this.table_Connection_Data.Supplier_Login.Disruption_Filters = event.api.getFilterModel()
                localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
                this.fetchData(event)
            }
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Supplier_Login?.Disruption_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        downloadRecallSummaryReport() {
            communicationServices.downloadRecallSummaryReport({
                status: 'All',
            })
        },
        downloadSupplierSummaryCSVReport() {
            communicationServices.downloadSupplierSummaryCSVReport({
                status: 'All',
            })
        },
        addMoreClick() {
            this.$router.push({ name: 'supp_Disruption_NewItem' })
        },
        viewRecall(item) {
            this.$router.push({ name: 'supp_Disruption_ViewItem', params: { id: item.id } })
        },
        editRecall(item, e) {
            this.$router.push({ name: 'supp_Disruption_EditItem', params: { id: item.id } })
        },
        deleteCommunication(item, e){
            this._showConfirm('Are you sure you want to delete the supply advisory, this cannot be undone?', {title: 'Delete Supply Advisory', variant: 'danger'}).then(confirm => {
                if (confirm) {
                    communicationServices.deleteCommunication(item.id,COMMUNICATION_TYPE.PRODUCTDISRUPTION).then(resp => {
                        if (!resp.error) {
                            this._showToast('Supply advisory deleted!')
                            this.$router.go()
                        }
                    })
                }
            })
        },
        fetchData(params) {

            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params) {
                    that.search.status = params.request?.filterModel?.status?.filter
                    that.search.recallName = params.request?.filterModel?.name?.filter
                    that.search.supplierRecallId = params.request?.filterModel?.communicationId?.filter
                    that.search.type = params.request?.filterModel?.type?.filter
                    that.search.classification = params.request?.filterModel?.classification?.filter
                    that.search.daysOpen = params.request?.filterModel?.days_open?.filter
                    that.search.communicationDate = params.request?.filterModel?.communicationDate?.dateFrom?.split(' ')[0]
                    that.search.nextexpectedupdate = params.request?.filterModel?.nextexpectedupdate?.dateFrom?.split(' ')[0]
                    that.search.notificationdate = params.request?.filterModel?.notificationdate?.dateFrom?.split(' ')[0]
                    that.search.anticipatedDurationId = params.request?.filterModel?.anticipatedDurationId?.filter
                    that.search.anticipatedDuration = params.request?.filterModel?.anticipatedDuration?.filter
                    that.search.lastupdate = params.request?.filterModel?.notificationdate?.dateFrom?.split(' ')[0]
                    that.search.numberOfProviders = params.request?.filterModel?.number_of_providers?.filter
                    that.search.nexttaskreminder = params.request?.filterModel?.nexttaskreminder?.dateFrom?.split(' ')[0]
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    communicationServices.findCommunications({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search,
                        organizationId: that.organization_id,
                        communicationType: COMMUNICATION_TYPE.PRODUCTDISRUPTION
                    }).then(resp => {
                        if (!resp.error) {
                            const recallsarray = []
                            for (var i = 0; i < resp.data.d.c.length; i++) {
                                var checkexist = recallsarray.filter(s => s.id == resp.data.d.c[i].id)
                                if (checkexist.length == 0) {

                                    recallsarray.push(resp.data.d.c[i])
                                }
                            }
                            that.total = resp.data.d.t//resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return recallsarray
                        }
                    }).then(response => {
                        params.successCallback(response)
                    })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        }
    }
}
